.Expertise {
    display: block;
    width: 80%;
    max-width: 80rem;
    flex-direction: column;
    color: white;
    margin: auto;    
}
.Expertise *{
    margin-bottom: 20px;
}
.Expertise .Section1-Wrapper{
    margin-bottom: 40px;
}
.Expertise .Row{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.Expertise .Section2-Wrapper .TextBlock{
    min-height: 18rem;
}
.Expertise .Section3-Wrapper .TextBlock{
    min-height: 11rem;
}

.Expertise .Animated-Container, .FAA .AnimatedSection{
    width: 100%;
    height: 100%;
}

.Expertise .Recommendations {
    margin-top: 5rem;
}
.Expertise h1 {
    text-align: center;
}

@media (max-width: 768px) {
    .Expertise {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
    }

    .Expertise .Row{
        flex-direction: column;
    }

    .Expertise .Row .TextBlock{
        height: auto;
    }

    .Expertise .Section2 {
        display: flex;
        flex-direction: column;
    }
}