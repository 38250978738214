/* The .navbar class is used for the navigation bar */
.navbar {
    position: fixed; /* Keeps the navbar at the top of the page even when scrolling */
    top: 0; /* Positions the navbar at the top of the page */
    width: 100%; /* Makes the navbar span the full width of the page */
    height: 4.5rem;
    background-color: black;
  }
  
  /* The .navbar ul rule styles the list in the navbar */
  .navbar ul {
    list-style-type: none; /* Removes the default list bullet points */
    display: flex; /* Makes the list items display in a row instead of a column */
    justify-content: flex-end; /* Aligns the list items to the right of the navbar */
    padding-right: 2rem; /* Adds some space to the right of the list */
    padding-top: .5rem; /* Adds some space to the top of the list */
  }
  
  /* The .navbar ul li rule styles the list items in the navbar */
  .navbar ul li {
    padding: 0 2rem; /* Adds some space to the left and right of each list item */
  }
  
  /* The .navbar a rule styles the links in the navbar */
  .navbar a {
    text-decoration: none; /* Removes the default link underline */
    color: white; /* Makes the link text white */
  }
  
  /* The .navbar .active-link rule styles the active link in the navbar */
  .navbar .active-link {
    color: rgb(153, 153, 153); /* Changes the color of the active link to grey */
  }
  
  @keyframes slideDown {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .animate-slide-down {
    animation: slideDown 0.5s ease-out;
  }

  @keyframes slideUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }

  .animate-slide-up {
    animation: slideUp 0.5s ease-out forwards; /* forwards ensures that the element remains at the end state after the animation */
  }

  /* The .navbar-toggle rule styles the hamburger icon */
  .navbar-toggle {
    display: none; /* Initially hidden */
    cursor: pointer;
    font-size: 2rem;
    color: white;
  }

  /* Media query for screens smaller than 768px */
  @media (max-width: 855px) {
    .navbar {
      display: grid; /* Makes the navbar a grid container */
      grid-template-columns: 1fr auto; /* Creates two columns, the first one taking up the remaining space, the second one auto-sized */
      grid-template-rows: auto auto; /* Creates two rows, both auto-sized */
      position: absolute; /* Makes the navbar a positioned element */
      z-index: 2;
    }

    .navbar .navbar-toggle {
      display: block; /* Shows the hamburger icon */
      grid-column: 2; /* Places the hamburger icon in the second column */
      grid-row: 1; /* Places the hamburger icon in the first row */
      align-self: center; /* Centers the hamburger icon vertically */
      padding-right: 2rem; /* Adds 2rem space to the right of the hamburger icon */
      padding-top: 1rem;
      z-index: 2;
    }

    .navbar ul {
      display: none; /* Initially hides the list items */
      grid-row: 2; /* Places the list items in the second row */
    }

    .navbar ul li {
      padding: 2rem 0; /* Adds vertical padding to each list item */
    }
    
    .navbar ul.collapsed {
      z-index: 1;
      margin: 0;
      padding: 0;
      display: flex; /* Shows the list items when the hamburger menu is clicked */
      position: absolute; /* Positions the list items relative to the navbar */
      width: 100%; /* Makes the list items span the full width of the screen */
      background-color: black;
      flex-direction: column; /* Stacks the list items vertically */
      justify-content: center; /* Centers the list items vertically */
      align-items: center; /* Centers the list items horizontally */
      animation: slideDown 0.5s ease-out;
    }
  }