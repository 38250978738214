.recommendation .TextBlock {
    display: flex;
    flex-direction: row;
}
.recommendation .TextBlock h3 {
    margin: .5rem 0;
}
.recommendation .profilePic img {
    width: 5rem;
    border-radius: 50%;
    margin-right: 1rem;
}