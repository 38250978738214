.Resume {
    display: flex;
    background-repeat: no-repeat; 
    background-size: cover;
    position: relative;
    width: 100vw;
    height: 100%;
    flex-direction: column;
    color: white;
    align-items: center;
}

.Resume .TextBlock{
    width: 50rem;
}

.download-section {
    position: fixed;
    left: 5rem;
    top: 15%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.download-button {
    width: 3rem;
    filter: invert(1);
}

.Text-Wrapper .TextBlock{
   margin-top: 2rem;
}

@media (max-width: 768px) {
    .Resume {
        flex-direction: column;
        align-items: center;
    }

    .Resume .TextBlock {
        width: 90%; /* Adjust as needed */
    }

    .download-section {
        position: static; /* Change from fixed to static */
        left: 0; /* Adjust as needed */
        top: 0; /* Adjust as needed */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .download-button {
        width: 3rem;
        filter: invert(1);
    }

    .Text-Wrapper .TextBlock {
        margin-top: 2rem;
    }
    .Text-Wrapper{
        padding-bottom: 5rem;
    }
}