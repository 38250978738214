.Home {
  background-repeat: no-repeat; 
  background-size: cover;
  position: relative;
  width: 100vw;
  height: 100%;
}

.Home .headshot {
  position: absolute; /* Positions the .headshot element relative to the .Home element */
  top: 45%;
  left: 50%; /* These two properties move the top-left corner of the .headshot element to the center of the .Home element */
  transform: translate(-50%, -50%); /* This moves the .headshot element up and to the left by half its width and height, effectively centering it */
  z-index: 1; /* This puts the .headshot element on top of the background */
  display: flex; /* Makes the .headshot a flex container */
  flex-direction: column; /* Stacks the children vertically */
  align-items: center; /* Centers the children horizontally */
}

.Home .headshot .headshot-container {
    width: 15rem;
    height: 15rem;
  }

.navigation {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.navigation .TextBlock {
  border-width: 0;
}
.navigation .TextBlock {
  margin: 0 1rem;
  color: white;
}

.navigation a {
  text-decoration: none;
}

.navigation a {
  transition: transform 0.3s;
}

.navigation a:hover {
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .navigation .TextBlock{
    margin: 0 .2rem;
  }
}