.ProjectSummary {
    color: white;
    display: grid;
    grid-template-columns: 4fr .15fr;
    align-items: start;
    gap: 20px;
    backdrop-filter: blur(10px);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 1);
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 1em;
    border: 1px solid transparent;
    border-image: linear-gradient(to right, rgba(66, 66, 66, 0.3), rgba(0, 0, 0, 0.2)) 1;
    border-width: 1px;
    border-style: solid;
}

.image-text-column {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
}

/* Add this media query */
@media (max-width: 1000px) {
    .ProjectSummary {
        grid-template-columns: 1fr .15fr;
    }
    .image-text-column {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
    }
}
.image-column {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-column img {
    width: 100%;
    height: auto;
}

.text-column {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 10px;
}
.icon-column {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.icon {
    height: 5rem;
    filter: invert(1);
}
.icon:hover {
    transform:  scale(1.1);
}
.column .icon-column{
    justify-content: flex-end;
    width: 1rem;
}

.read-more {
    display: none;
}

@media (max-width: 768px) {
    .ProjectSummary {
        grid-template-columns: 1fr;
    }

    .icon-column {
        justify-content: center;
        align-items: flex-end;
    }

    .icon {
        display: none;
    }

    .read-more {
        display: block;
        height: 3rem;
        width: 15rem;
        backdrop-filter: blur(90px);
        background-color: rgba(0, 0, 0, 0.9);
        color: white;
        border: 0px
    }
}