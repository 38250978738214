.Projects {
    width: 80%; /* Adjust this to your liking */
    margin: 0 auto; /* This will center the div if the width is less than 100% */;
    margin-bottom: 5rem;
    max-width: 80rem;
}
.Projects .ProjectSummary {
    margin-top: 2rem;
}

@media (max-width: 768px) {
    .Projects {
        width: 90%; /* Adjust this to your liking */
    }
}