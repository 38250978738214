.TextBlock {
    backdrop-filter: blur(10px); /* Applies a blur effect to the background */
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 1); /* Adds a slight drop shadow */
    background-color: rgba(0, 0, 0, 0.2); /* Blue background with 50% transparency */
    border-radius: 10px; /* Rounds the corners */
    padding: 1em; /* Adds some space around the text */
    border: 1px solid transparent; /* The width can be adjusted as needed */
    /* Apply a gradient to the border with transparency */
    border-image: linear-gradient(to right, rgba(66, 66, 66, 0.3), rgba(0, 0, 0, 0.2)) 1;
}

.TextBlock pre, .TextBlock code {
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}