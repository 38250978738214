.About {
    display: flex;
    background-repeat: no-repeat; 
    background-size: cover;
    position: relative;
    width: 100vw;
    height: 100%;
    justify-content: center;
}

.About-text {
    color: white;
    width: 40rem;
}
.image-section {
    padding-right: 5rem;
    padding-top: 8rem;
}
.About-image {
    width: auto; 
    height: 30rem; 
    border: 5px solid black; 
    border-radius: 50%; 
}
.icon-section {
    display: flex;
    justify-content: center;
    gap: 1rem;
    transform: translateX(11px);
}

.icon-section img {
    height: 40px;
}

.icon-section img.email-icon {
    transform: translateY(-35px);
    filter: invert(1);
}

.icon-section img.github-icon {
    transform: translateY(-10px);
    filter: invert(1);
}

.icon-section img.linkedin-icon {
    transform: translateY(-7px);
}

.icon-section img.contact-icon {
    transform: translateY(-33px);
    filter: invert(1);
}

@media (max-width: 768px) {
    .About {
        padding: 0;
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .About-text {
        width: 90%; /* Adjust as needed */
        padding-bottom: 5rem;
    }

    .image-section {
        padding-right: 0;
        padding-top: 2rem; /* Adjust as needed */
    }

    .About-image {
        transform: translateX(22vw);
        width: 50%; /* Adjust as needed */
        height: auto;
    }

    .icon-section {
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        transform: translateX(0);
    }
}