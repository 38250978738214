.App {
  display: flex;
  background-repeat: no-repeat; 
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.App::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the last value as needed to get the desired level of dimming */
  z-index: 1;
}

.App .body {
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 4rem;
  padding-top: 2rem;
  width: 100%;
}

.footer {
  padding-top: 3rem;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
}

a {
  color: #1E90FF; /* Dodger Blue */
  text-decoration: none; /* Optional: Removes underline */
}
a:hover, a:focus {
  color: #00BFFF; /* Deep Sky Blue */
  text-decoration: underline; /* Re-adds underline on hover/focus for clarity */
}
