
.header .name-title {
  text-align: left;
  margin-left: 20px; /* Adjust as needed */
}

.header .name-title h1 {
  font-size: 1em; /* Adjust as needed */
  margin-bottom: 0;
}

.header .name-title h2 {
  font-size: 0.8em; /* Adjust as needed */
  margin-top: 0;
}

.header .headshot {
  position: absolute;
  top: .5rem; /* Adjust as needed */
  left: 1rem; /* Adjust as needed */
  display: flex;
  align-items: center;
  width: max-content;
}

.header .headshot .headshot-container {
  width: 3rem; /* Adjust as needed */
  height: 3rem; /* Adjust as needed */
}

.header{
  z-index: 3;
}