.FAA {
    width: 80%;
    max-width: 80rem;
    color: white;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}

.FAA > * {
    margin-bottom: 3rem;
    width: 100%;
}

.Header {
    width: 70%;
}

.FAA .Animated-Container, .FAA .AnimatedSection{
    width: 100%;
}

.FAA .image-container img {
    width: 40rem; /* adjust the width */
    height: fit-content;
}

@media (max-width: 768px) {
    .FAA .image-container img {
        width: 90%; /* adjust the width */
        height: auto;
    }
    .FAA {
        width: 90%;
        padding-bottom: 5rem;
    }
}