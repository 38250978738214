.Animated-Container .TextBlock {
    opacity: 0;
    transition: opacity .75s ease-in-out, transform .75s ease-in-out;
    transform: translateX(-100%);
}

.Animated-Container.Visible .TextBlock {
    opacity: 1;
    transform: translateX(0);
}

.Animated-Container .image-container img {
    opacity: 0;
    transition: opacity .75s ease-in-out, transform .75s ease-in-out;
    transform: translateX(-100%);
}

.Animated-Container.Visible .image-container img:nth-child(1) {
    transition-delay: .5s;
    opacity: 1;
    transform: translateX(0);
}

.Animated-Container.Visible .image-container img:nth-child(2){
    transition-delay: 1s;
    opacity: 1;
    transform: translateX(0);
}
.Animated-Container.Visible .image-container img:nth-child(3){
    transition-delay: 1.5s;
    opacity: 1;
    transform: translateX(0);
}
.Animated-Container.Visible .image-container img:nth-child(4){
    transition-delay: 2s;
    opacity: 1;
    transform: translateX(0);
}

@media(prefers-reduced-motion){
    .Expertise .Text-Container{
        transition: none;
    }
}