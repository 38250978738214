.Portfolio {
    width: 80%;
    max-width: 80rem;
    color: white;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}

.Portfolio > * {
    margin-bottom: 3rem;
    width: 100%;
}

.Portfolio .Header-Wrapper{
    width: 100%;
}

.Portfolio .Animated-Container {
    width: 100%;
}

@media (max-width: 768px) {
    .Portfolio {
        width: 90%;
        padding-bottom: 5rem;
    }
}