.headshot-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid white;
    overflow: hidden;
  }
  
  .headshot-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: scale(1.1);
  }